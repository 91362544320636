import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { colors, radius } from 'theme'
import Modal from 'components/Modal'
import { Box, Grid, Flex } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import Input from 'components/Input'
import Button from 'components/Button'
import ErrorFocus from 'components/ErrorFocus'
import { H2, Text } from 'components/Typography'
import DropDown from 'components/DropDown'
import clear from '../../../static/svg/clear.svg'

const IconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${colors.white};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const { FIRST_NAME, LAST_NAME, EMAIL, HAS_PROFILE, COMPANY_TYPE } = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  HAS_PROFILE: 'has_profile',
  COMPANY_TYPE: 'company_type',
}

const HAS_PROFILE_DATA = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '0',
  },
]

const COMPANY_TYPES = [
  {
    label: 'Agency',
    value: 'AGENCY',
  },
  {
    label: 'Business',
    value: 'BUSINESS',
  },
  {
    label: 'Software',
    value: 'SOFTWARE',
  },
]

const SubscribeModal = ({ isOpen, handleDismiss, handleSubmit, isSubmittingProfile }) => {
  const recaptchaRef = useRef()
  const [formValues, setFormValues] = useState({})

  const createUserValidationSchema = () => {
    const isDropdownValueRequiredShape = {
      value: Yup.string().required(),
      label: Yup.string().required(),
    }
    return Yup.object().shape({
      [FIRST_NAME]: Yup.string()
        .required('First name cannot be empty')
        .max(50, 'First name is too long - should be 50 chars maximum.'),
      [LAST_NAME]: Yup.string()
        .required('Last name cannot be empty')
        .max(50, 'Last name is too long - should be 50 chars maximum.'),
      [EMAIL]: Yup.string()
        .email('Must be a valid email address')
        .required('Email cannot be empty')
        .max(50, 'Email is too long - should be 50 chars maximum.'),
      [COMPANY_TYPE]: Yup.object()
        .shape(isDropdownValueRequiredShape)
        .typeError('Please select an option from the dropdown menu'),
      [HAS_PROFILE]: Yup.object()
        .shape(isDropdownValueRequiredShape)
        .typeError('Please select an option from the dropdown menu'),
    })
  }

  const handleSubmitReCaptcha = async (values) => {
    setFormValues(values)
    const token = await recaptchaRef.current.executeAsync()
  }

  const handleSubmitProfileForm = async () => {
    const body = {
      [FIRST_NAME]: formValues[FIRST_NAME],
      [LAST_NAME]: formValues[LAST_NAME],
      [EMAIL]: formValues[EMAIL],
      [HAS_PROFILE]: formValues[HAS_PROFILE].value,
      [COMPANY_TYPE]: formValues[COMPANY_TYPE].value,
      campaign: 'the ultimate guide for marketers',
    }
    handleSubmit({ body })
  }

  return (
    <Modal isOpen={isOpen} handleDismiss={handleDismiss} maxwidth="880px" translatetop="70">
      <Box maxWidth="900px" px="m" py="m">
        <H2 textAlign="center">State of Social Media Marketing: Agency Edition</H2>
        <Formik
          initialValues={{
            [FIRST_NAME]: '',
            [LAST_NAME]: '',
            [EMAIL]: '',
            [HAS_PROFILE]: [],
            [COMPANY_TYPE]: [],
          }}
          onSubmit={handleSubmitReCaptcha}
          validationSchema={createUserValidationSchema()}
          autocomplete="off"
        >
          {({ values, handleChange, setFieldValue, handleSubmit, touched, errors }) => (
            <React.Fragment>
              <Grid mt="m" gridGap="m" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
                <Input
                  label="First Name"
                  placeholder="First Name"
                  value={values[FIRST_NAME]}
                  name={[FIRST_NAME]}
                  id={[FIRST_NAME]}
                  onChange={handleChange(FIRST_NAME)}
                  error={errors[FIRST_NAME] && touched[FIRST_NAME] && errors[FIRST_NAME]}
                  width="100%"
                />
                <Input
                  label="Last Name"
                  placeholder="Last Name"
                  value={values[LAST_NAME]}
                  name={[LAST_NAME]}
                  id={[LAST_NAME]}
                  onChange={handleChange(LAST_NAME)}
                  error={errors[LAST_NAME] && touched[LAST_NAME] && errors[LAST_NAME]}
                  width="100%"
                />
              </Grid>
              <Box mt="m">
                <Input
                  label="Business Email"
                  placeholder="Business Email"
                  value={values[EMAIL]}
                  name={[EMAIL]}
                  id={[EMAIL]}
                  onChange={handleChange(EMAIL)}
                  error={errors[EMAIL] && touched[EMAIL] && errors[EMAIL]}
                  width="100%"
                />
              </Box>
              <Grid gridGap="m" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
                <Box mt="m">
                  <DropDown
                    placeholder="Do you already have a profile?"
                    label=""
                    value={values[HAS_PROFILE]}
                    id={[HAS_PROFILE]}
                    onChange={(option) => {
                      setFieldValue(HAS_PROFILE, option)
                    }}
                    options={HAS_PROFILE_DATA}
                    error={errors[HAS_PROFILE] && touched[HAS_PROFILE] && errors[HAS_PROFILE]}
                    openMenuOnFocus
                  />
                </Box>
                <Box mt="m">
                  <DropDown
                    placeholder="What is your company?"
                    label=""
                    value={values[COMPANY_TYPE]}
                    id={[COMPANY_TYPE]}
                    onChange={(option) => {
                      setFieldValue(COMPANY_TYPE, option)
                    }}
                    options={COMPANY_TYPES}
                    error={errors[COMPANY_TYPE] && touched[COMPANY_TYPE] && errors[COMPANY_TYPE]}
                    openMenuOnFocus
                  />
                </Box>
              </Grid>
              <Flex mt="l" justifyContent="center">
                <Button.Primary
                  onClick={() => {
                    if (!isSubmittingProfile) {
                      handleSubmit()
                    }
                  }}
                  type="button"
                >
                  Download Now
                </Button.Primary>
              </Flex>
              <Text mt="xs" textAlign="center" fontSize="xxs">
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </Text>
              <ErrorFocus />
            </React.Fragment>
          )}
        </Formik>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
          onChange={handleSubmitProfileForm}
        />
      </Box>
      <IconWrapper onClick={handleDismiss}>
        <LocalImage width="10px" height="10px" src={clear} />
      </IconWrapper>
    </Modal>
  )
}

SubscribeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmittingProfile: PropTypes.bool.isRequired,
}

export default SubscribeModal
