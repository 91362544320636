import React, { useState } from 'react'
import styled from 'styled-components'
import App from 'App'
import { colors } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, H3, H2, Text } from 'components/Typography'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import Button from 'components/Button'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import checkmarkWithCicle from 'static/svg/checkmark_with_circle.svg'
import bgImage from 'static/images/bg_ppckit.png'
import SubscribeModal from './components/SubscribeModal'

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const ButtonLink = styled(Button.Primary)`
  display: flex;
  text-decoration: none;
`

const BENEFITS = [
  'Insights and data from over 218 verified marketing agencies',
  '11 expert predictions for the year ahead',
  '20 charts of reliable data in simple displays for presentations and quick decision making',
  'Benchmarks for digital marketing agencies in 2021',
  'The secret to client success ahead the busy holiday season',
]

const GoogleAdsPPCKit = () => {
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false)
  const [isSubmittingProfile, setIsSubmittingProfile] = useState(false)

  const handleSubmitProfileForm = async ({ body }) => {
    setIsSubmittingProfile(true)
    try {
      const response = await fetch(`${process.env.GATSBY_API_URL}/subscribe-campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })

      if (response && !response.error) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `AgencyVista_StateOfSocialMediaMarketing_Report_2021-final.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        console.log('error', response.error)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmittingProfile(false)
      setIsSubscribeModalOpen(false)
    }
  }

  return (
    <App>
      <SEO
        title="State of Social Media Marketing: Agency Edition"
        description="A new report by Agency Vista and Sendible to help marketers fuel their strategy for the busy holiday season ahead. Industry leaders such as Jay Baer and Nilla Ali have come alongside over 218 verified marketing agencies to share their insights and predictions. Let's take a look inside!"
        path="/state-of-social-media-marketing/"
        image="/img/discover_perfect_social_agency.jpg"
      />
      <Hero>
        <BgImg src={bgImage} />
        <H1 color="white">State of Social Media Marketing: Agency Edition</H1>
        <Box mt="m" maxWidth="1040px">
          <StyledH3 color="white">
            A new report by Agency Vista and Sendible to help marketers fuel their strategy for the busy holiday season
            ahead. Industry leaders such as Jay Baer and Nilla Ali have come alongside over 218 verified marketing
            agencies to share their insights and predictions. Let's take a look inside!
          </StyledH3>
        </Box>
        <Box mt="m">
          <ButtonLink
            as="a"
            onClick={() => {
              setIsSubscribeModalOpen(true)
            }}
          >
            <Text fontWeight="medium">Download the Full Report</Text>
          </ButtonLink>
        </Box>
      </Hero>
      <Container maxWidth="1080px">
        <Flex mt="xxl" px="l" justifyContent="center" flexWrap="wrap">
          <H1 fontWeight="bold" textAlign="center">
            Data and Benchmarks to Fuel Your Growth
          </H1>
        </Flex>
        <Flex mt="xl" flexDirection="column" width="100%" px="m" alignItems="center">
          <H2>Here’s what’s in the full report:</H2>
          <Flex mt="m" mb="l" pb="m" flexDirection="column">
            {BENEFITS.map((item) => (
              <Flex mt="xs" mb="m" alignItems="center" key={item}>
                <LocalImage source={checkmarkWithCicle} width="20px" height="20px" alt={item} />
                <Text ml="m" fontSize="l">
                  {item}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Container>
      <Flex
        color={colors.white}
        px="l"
        mt="xl"
        py="xl"
        flexDirection="column"
        alignItems="center"
        fontWeight="medium"
        backgroundColor={colors.primary}
      >
        <H1 textAlign="center">The Ultimate Guide for Marketers</H1>
        <Box maxWidth="768px" px="m">
          <Text mt="m" lineHeight="150%" fontSize="l" textAlign="center" whiteSpace="pre-line">
            Marketing agencies continually evolve through the growth of technology and the rise of social media
            marketing. Understanding this evolution can assist in setting benchmarks for other agencies, bring more
            transparency for businesses, and improve products in the martech space.
          </Text>
          <br />
          <Text mt="m" lineHeight="150%" fontSize="l" textAlign="center" whiteSpace="pre-line">
            We asked 218 marketing agencies around the world about their marketing strategies and plans for 2021, and
            it's clear that their client management, internal processes, and tech stack have shifted for the future.
          </Text>
        </Box>
      </Flex>
      <Flex px="l" mt="xl" py="xl" backgroundColor={colors.white}>
        <Container>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <H1 textAlign="center">State of Social Media Marketing: Agency Edition</H1>
            <Box mt="l">
              <Button.Primary
                onClick={() => {
                  setIsSubscribeModalOpen(true)
                }}
              >
                Download the Full Report
              </Button.Primary>
            </Box>
          </Flex>
        </Container>
      </Flex>
      <Footer />
      <SubscribeModal
        handleDismiss={() => {
          setIsSubscribeModalOpen(false)
        }}
        isOpen={isSubscribeModalOpen}
        handleSubmit={handleSubmitProfileForm}
        isSubmittingProfile={isSubmittingProfile}
      />
    </App>
  )
}

export default GoogleAdsPPCKit
