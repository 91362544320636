import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'
import { transparentize } from 'polished'
import { space, radius, colors, fontSizes, fontWeights, COLOR_CONSTANTS } from 'theme'
import { pxToRem } from 'helpers'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import ErrorLabel from 'components/ErrorLabel'
import LocalImage from 'components/LocalImage'
import chevronDown from '../static/svg/chevron_down.svg'

const getOptionBackgroundColorFromState = ({ isSelected, isFocused }) => {
  if (isSelected) {
    return colors.primary
  }
  if (isFocused) {
    return transparentize(0.75, colors.primary)
  }
  return colors.white
}

const getOptionColorFromState = ({ isSelected, isFocused }) => {
  if (isSelected) {
    return colors.white
  }
  if (isFocused) {
    return colors.black
  }
  return colors.black
}

// This is needed in order to turn off the autocomplete, more info here:
// https://github.com/JedWatson/react-select/issues/3500
// Also, Chrome doesn't support turning off autocomplete without a workaround
// https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
// "none" is just a random invalid word
const Input = ({ ...props }) => <components.Input {...props} autoComplete="none" />

const DropdownIndicator = ({ ...props }) => (
  <Box mr="m">
    <LocalImage source={chevronDown} />
  </Box>
)
const IndicatorSeparator = () => null

const Select = ({ height, width, label, error, innerRef, ...props }) => {
  const isError = !!error
  return (
    <React.Fragment>
      {label && <Text mb="xs">{label}</Text>}
      <ReactSelect
        styles={{
          placeholder: (base) => ({
            ...base,
            color: COLOR_CONSTANTS.COSMIC_ENERGY,
            fontSize: fontSizes.s,
            fontWeight: fontWeights.normal,
          }),
          container: (base) => ({
            ...base,
            width,
          }),
          control: (base, state) => ({
            ...base,
            height,
            fontSize: fontSizes.s,
            paddingLeft: pxToRem(6),
            paddingRight: pxToRem(6),
            borderRadius: '2px',
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: isError
              ? colors.primary
              : state.isFocused
              ? COLOR_CONSTANTS.SOLITUDE
              : COLOR_CONSTANTS.SOLITUDE,
            '&:hover': {
              borderColor: state.isFocused ? COLOR_CONSTANTS.SOLITUDE : COLOR_CONSTANTS.SOLITUDE,
            },
          }),
          option: (base, state) => {
            const backgroundColor = getOptionBackgroundColorFromState(state)
            const color = getOptionColorFromState(state)
            return {
              ...base,
              fontSize: fontSizes.s,
              fontWeight: 'medium',
              backgroundColor,
              color,
            }
          },
          multiValue: (base) => ({
            ...base,
            backgroundColor: 'transparent',
          }),
          multiValueLabel: (base) => ({
            ...base,
            padding: space.xs,
            fontWeight: 'medium',
            borderRadius: radius.s,
            color: colors.primary,
            backgroundColor: transparentize(0.9, colors.primary),
          }),
          multiValueRemove: (base) => ({
            ...base,
            backgroundColor: transparentize(0.9, colors.primary),
            color: transparentize(0.6, colors.primary),
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: colors.primary,
            primary50: transparentize(0.25, colors.primary),
          },
        })}
        components={{ Input, DropdownIndicator, IndicatorSeparator }}
        ref={innerRef}
        {...props}
      />
      {error && <ErrorLabel error={error} mt="s" />}
    </React.Fragment>
  )
}

Select.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
}

export default Select
